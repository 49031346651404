import { css } from "@emotion/react";
import Select from "react-select";
import { useMediaQuery } from "react-responsive";
import React from "react";

import {
  colors,
  borders,
  timings,
  breakpoints,
} from "@mytutor/shared-react-web-components/lib/theme";

const options = (subjects) => [
  {
    label: "Popular subjects",
    options: [
      {
        label: (
          <div className="option">
            <img
              className="option-image"
              src="/landing/subject-maths.svg"
              alt="Maths icon"
            />
            <span>Maths</span>
          </div>
        ),
        value: "Maths",
      },
      {
        label: (
          <div className="option">
            <img
              className="option-image"
              src="/landing/subject-english.svg"
              alt="English icon"
            />
            <span>English</span>
          </div>
        ),
        value: "English",
      },
      {
        label: (
          <div className="option">
            <img
              className="option-image"
              src="/landing/subject-biology.svg"
              alt="Biology icon"
            />
            <span>Biology</span>
          </div>
        ),
        value: "Biology",
      },
      {
        label: (
          <div className="option">
            <img
              className="option-image"
              src="/landing/subject-chemistry.svg"
              alt="Chemistry icon"
            />
            <span>Chemistry</span>
          </div>
        ),
        value: "Chemistry",
      },
      {
        label: (
          <div className="option">
            <img
              className="option-image"
              src="/landing/subject-physics.svg"
              alt="Physics icon"
            />
            <span>Physics</span>
          </div>
        ),
        value: "Physics",
      },
    ],
  },
  {
    label: "All subjects",
    options: subjects
      ? subjects.map((subject) => ({
          label: (
            <div className="option">
              <span>{subject.subject}</span>
            </div>
          ),
          value: subject.urlSubject,
        }))
      : [],
  },
];

const DropdownIndicator = () => (
  <img
    className="dropdown-image"
    src="/landing/search-neutral8.svg"
    alt="Search icon"
  />
);

const customStyles = {
  container: (base) => ({
    ...base,
    borderRadius: `${borders.radius}`,
    outline: 0,
    caretColor: `${colors.primary5}`,
    fontWeight: 500,
    whiteSpace: "nowrap",
  }),

  indicatorSeparator: () => ({ display: "none" }),

  option: (base, state) => ({
    ...base,
    backgroundColor: `${
      state.isFocused
        ? colors.primary5
        : state.isSelected
        ? colors.primary5
        : colors.neutral1
    }`,
    color: `${
      state.isFocused
        ? colors.neutral1
        : state.isSelected
        ? colors.neutral1
        : colors.neutral8
    }`,
    transition: `background-color ${timings.quick}`,
    "&:hover": {
      backgroundColor: `${colors.primary5}`,
    },
  }),

  control: (base, state) => ({
    ...base,
    minHeight: 48, // to override the base style min-height :/
    boxShadow: state.isFocused ? `0 0 5px transparent` : 0,
    border: "none",
    backgroundColor: `${colors.neutral1}`,
    transition: `border ${timings.medium}`,
    borderRadius: `${borders.radius}`,
    "&:hover": {
      borderColor: `${colors.primary7}`,
    },
  }),

  menu: (styles) => ({
    ...styles,
    boxShadow: "0 8px 16px 0 rgba(24, 19, 87, 0.07)",
    backgroundColor: colors.neutral1,
    borderRadius: `${borders.radius}`,
    border: "0px",
    padding: "0px",
    margin: breakpoints.phone ? "10px 0px 0px 0px" : "0",
    zIndex: 999,
  }),
};

const subjectPickerDropdownStyles = css`
  .option-image {
    height: 1.5em;
    width: 1.5em;
    margin-inline-end: 1em;
    vertical-align: middle;
  }

  .option-image + span {
    vertical-align: middle;
  }

  .option {
    height: 1;
    line-height: 1;
    padding: 8px 0;
  }

  .dropdown-image {
    height: 1.3em;
    width: 1.3em;
    margin-block-start: 0.3em;
    margin-inline-end: 1em;
  }
`;

const SubjectPickerDropdown = ({ subjects, onChange, onFocus, onBlur }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 480px)` });

  return (
    <Select
      instanceId="subject-picker"
      css={subjectPickerDropdownStyles}
      styles={customStyles}
      components={{ DropdownIndicator }}
      options={options(subjects)}
      aria-label="Select subject"
      placeholder="Search a subject"
      onChange={onChange}
      menuPlacement={isMobile ? "bottom" : "top"}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default SubjectPickerDropdown;
